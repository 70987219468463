import { reactive, toRefs, defineAsyncComponent, watch, nextTick, computed } from 'vue';
import { useStore } from 'vuex';
import { useDebounce } from '@/hooks/core/useDebounce';
import API from "@/api/authority/index";
import { ElMessageBox } from "element-plus";
import { getAreaStr } from "../education/function";
import { useMessage } from '@/hooks/web/useMessage';
import { Search, Plus } from "@element-plus/icons-vue";
import { formatDate } from '@/utils/formatTime';
export default {
  name: "Organization",
  components: {
    TreeSelect: defineAsyncComponent(() => import('@/components/TreeSelect/TreeSelect.vue')),
    BasicTemplate: defineAsyncComponent(() => import('@/components/BasicTemplateTablePage/index.vue')),
    AddOrganization: defineAsyncComponent(() => import('./components/AddOrganization.vue')),
    SchoolFormDialog: defineAsyncComponent(() => import('../education/components/SchoolFormDialog.vue')),
    AddOrgSchool: defineAsyncComponent(() => import('./components/AddOrgSchool.vue')),
    SchoolTable: defineAsyncComponent(() => import('./SchoolTable.vue')),
    Eldialog: defineAsyncComponent(() => import('@/components/Eldialog/index.vue'))
  },
  setup() {
    const {
      commit,
      state
    } = useStore();
    const message = useMessage();
    const refData = reactive({
      treeSelectRef: null,
      activeTypeId: null,
      addVisible: false,
      schoolTableRef: null,
      schoolId: null,
      activeType: 'org',
      formVisible: false,
      orgInfo: {
        id: 0,
        name: null,
        activeValue: null
      },
      schoolInfo: null,
      addOrganizationShow: false,
      loading: false,
      total: 0,
      current: 1,
      pageSize: 100,
      searchForm: {
        name: '',
        childFlag: true,
        parentId: null
      },
      activeOrgId: null,
      tableData: [],
      tableColumns: [{
        title: '序号',
        align: 'center',
        key: 'index',
        width: 60,
        slots: {
          customRender: 'index'
        },
        visible: true
      }, {
        title: '组织名称',
        align: 'center',
        key: 'name',
        visible: true
      }, {
        title: '登录账号',
        align: 'center',
        key: 'loginName',
        visible: true
      }, {
        title: '上级名称',
        align: 'center',
        key: 'parentName',
        visible: true
      }, {
        title: '所属区域',
        align: 'center',
        key: 'address',
        slots: {
          customRender: 'address'
        },
        visible: true
      }, {
        title: '创建时间',
        align: 'center',
        key: 'createTime',
        visible: true
      }, {
        fixed: 'right',
        title: '操作',
        align: 'center',
        key: 'operate',
        width: 200,
        slots: {
          customRender: 'operate'
        },
        visible: true
      }],
      activeOrg: {
        orgPid: null,
        orgName: '',
        activeValue: null
      }
    });
    const id = computed(() => state.treeData.length ? state.treeData[0][0].id : null);
    const isSchool = computed(() => state.userInfo ? state.userInfo.hasOwnProperty('schoolId') : false);
    const onSearch = async () => {
      refData.loading = true;
      try {
        const params = {
          id: refData.searchForm.parentId,
          childFlag: refData.searchForm.childFlag ? 1 : 0,
          name: refData.searchForm.name,
          pageSize: refData.pageSize,
          pageNo: refData.current
        };
        const {
          data,
          msg,
          code
        } = await API.getOrganizationList(params);
        refData.total = data.total;
        if (code != 0) throw msg;
        if (data.list) {
          refData.tableData = data.list.map(item => {
            item.createTime = formatDate(item.createTime);
            return item;
          });
        }
        //  refData.tableData = data.list.map((item:any)=>({ 
        //     ...item 
        //   })); 
        refData.loading = false;
      } catch (error) {
        refData.tableData = [];
        refData.loading = false;
        message.warning(error);
      }
    };
    watch(() => id.value, val => {
      nextTick(() => {
        val && (refData.searchForm.parentId = val
        // debounceOnSearch()
        );
      });
    });
    nextTick(() => {
      refData.searchForm.parentId = state.treeData.length ? state.treeData[0][0].id : null;
      // debounceOnSearch();
    });
    //重置密码
    const handleResetPassword = async (userId, name) => {
      ElMessageBox.confirm(`是否确认重置“${name}”该账号密码`, '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        const {
          code,
          msg
        } = await API.resetPassword({
          id: userId,
          password: "A123456"
        });
        if (code == 0) {
          ElMessageBox.confirm(`重置密码成功，默认密码为：A123456`, '提示', {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "success"
          }).then(async () => {
            debounceOnSearch();
          }).catch(() => {
            // ElMessage.info("已取消退出");
          });
        } else {
          message.warning(msg);
        }
      }).catch(() => {
        // ElMessage.info("已取消退出");
      });
    };
    //分页
    const currentChange = val => {
      refData.current = val;
      debounceOnSearch();
    };
    //页码
    const pageSizeChange = val => {
      refData.pageSize = val;
      debounceOnSearch();
    };
    //点击组织树
    const handleClick = data => {
      refData.activeType = data.type;
      refData.activeTypeId = data.typeId;
      refData.searchForm.parentId = data.id;
      refData.activeOrg.orgPid = data.id;
      refData.activeOrg.orgName = data.name;
      refData.activeOrg.activeValue = data.typeId;
      refData.orgInfo = {
        id: data.id,
        name: data.name,
        activeValue: data.name
      };
      debounceOnSearch();
    };
    //修改组织
    const handleEdit = async orgId => {
      refData.activeOrgId = orgId;
      refData.addOrganizationShow = true;
    };
    //删除组织
    const handleDel = async (orgId, name) => {
      ElMessageBox.confirm(`是否确认删除“${name}”该组织`, '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        const {
          code,
          msg
        } = await API.deleteOrganization({
          id: orgId
        });
        code == 0 && (refData.tableData = [], refData.treeSelectRef.handleRemove(`org-${orgId}`));
        message[code == 0 ? 'success' : 'warning'](code == 0 ? '删除成功' : msg);
      }).catch(() => {
        // ElMessage.info("已取消退出");
      });
    };
    //添加成功回调
    const handleSuccess = () => {
      debounceOnSearch();
    };
    //点击加号
    const handleAddData = data => {
      //新增组织
      refData.searchForm.parentId = data.id;
      refData.activeOrg.orgPid = data.id;
      refData.activeOrg.orgName = data.name;
      refData.activeOrg.activeValue = data.typeId;
      refData.activeTypeId = data.typeId;
      refData.activeOrgId = null;
      //新增单位
      refData.orgInfo = {
        id: data.id,
        name: data.name,
        activeValue: data.name
      };
      refData.schoolInfo = null;
      refData.addVisible = true;
    };
    const [debounceOnAddData] = useDebounce(handleAddData, 200);
    const [debounceOnSearch] = useDebounce(onSearch, 200);
    // 初始化函数调用
    return {
      ...toRefs(refData),
      currentChange,
      pageSizeChange,
      debounceOnSearch,
      handleClick,
      handleEdit,
      handleDel,
      getAreaStr,
      handleSuccess,
      debounceOnAddData,
      handleResetPassword,
      isSchool,
      Search,
      Plus
    };
  }
};